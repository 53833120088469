export const dataDogContext = {
  current: {
    applicationRef: '',
    applicationId: '',
  },
};

export function updateDataDogContext(data) {
  dataDogContext.current = { ...dataDogContext.current, ...data };
}

export function beforeSend(event) {
  // eslint-disable-next-line no-param-reassign
  event.context = { ...event.context, ...dataDogContext.current };
}

export function loadDataDogRum() {
  try {
    import(/* webpackChunkName: "datadogBrowserRum" */ '@datadog/browser-rum').then(({ datadogRum }) => {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        env: process.env.REACT_APP_ENV,
        version: process.env.REACT_APP_VERSION,
        service: process.env.REACT_APP_SERVICE,
        allowedTracingOrigins: [new URL(process.env.REACT_APP_ACQ_APP_APPLY_BFF_URL).origin],
        trackUserInteractions: true, // Used to enable Heatmap capture
        sessionSampleRate: process.env.REACT_APP_ENV === 'prod' ? 100 : 0,
        sessionReplaySampleRate: process.env.REACT_APP_ENV === 'prod' ? 100 : 0,
        startSessionReplayRecordingManually: true,
        beforeSend,
      });
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error loading Datadog RUM', error);
  }
}
