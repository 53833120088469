import React, { useState } from 'react';
import { useBreakpoint, Grid, Container, Text, List, Link } from 'basis';
import { OverviewSources, PRODUCT_CODE_AFS, PRODUCT_CODE_AU_GEM, PRODUCT_CODE_GO, config } from '_config';
import { useStoreValue } from 'store';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { byCard } from 'utils/byConfig';
import { VerifierModal } from '../landing-page-dj/VerifierModal';
import { DeclarationModal } from '../landing-page-dj/DeclarationModal';
import { PrivacyNoticeModal } from './PrivacyNoticeModal';

const formTealiumLocation = 'landing';

export const ProductTermsAndConditions = () => {
  const breakpoint = useBreakpoint();

  const [verifierModalOpen, setVerifierModalOpen] = useState(false);
  const [declarationModalOpen, setDeclarationModalOpen] = useState(false);
  const [privacyNoticeModalOpen, setPrivacyNoticeModalOpen] = useState(false);

  const [storeState] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const keyFactsSheetLink = byCard({
    [PRODUCT_CODE_GO]: OverviewSources.AU.go_key_facts_sheet,
    [PRODUCT_CODE_AFS]: OverviewSources.AU.afs_key_facts_sheet,
    [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.key_facts_sheet,
  });

  const conditionsOfUseLink = byCard({
    [PRODUCT_CODE_GO]: OverviewSources.AU.go_conditions_of_use,
    [PRODUCT_CODE_AFS]: OverviewSources.AU.afs_conditions_of_use,
    [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.gem_conditions_of_use,
  });

  const openVerifierModal = () => {
    setVerifierModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: "Verifier's terms of service",
    });
  };

  const closeVerifierModal = () => {
    setVerifierModalOpen(false);
  };

  const openDeclarationModal = () => {
    setDeclarationModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: 'Declaration and Credit Application',
    });
  };

  const closeDeclarationModal = () => {
    setDeclarationModalOpen(false);
  };

  const openPrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: 'Privacy Notice',
    });
  };

  const closePrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(false);
  };

  return (
    <>
      <VerifierModal isOpen={verifierModalOpen} onClose={closeVerifierModal} />
      <DeclarationModal isOpen={declarationModalOpen} onClose={closeDeclarationModal} />
      <PrivacyNoticeModal isOpen={privacyNoticeModalOpen} onClose={closePrivacyNoticeModal} />
      <Grid.Item colSpan="all" colSpan-md="0-1" colSpan-lg="0-1" rowSpan="8" rowSpan-md="4" margin-md="0">
        <Container margin="0 0 4 0" margin-md="0 5 0 0" padding="6 0 0 0" padding-md="0 5 0 0">
          <Text textStyle="heading4">By starting this application you agree:</Text>
        </Container>
      </Grid.Item>
      <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="9" rowSpan-md="4">
        <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
          <List>
            <List.Item>
              <Link
                newTab
                href={byCard({
                  [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.latitude_gem_visa.url,
                  [PRODUCT_CODE_GO]: OverviewSources.AU.latitude_go_card.url,
                  [PRODUCT_CODE_AFS]: OverviewSources.AU.latitude_afs_card.url,
                })}
                onClick={() =>
                  trackEventSender({
                    action: 'external-link',
                    label: config.productName,
                    location: formTealiumLocation,
                  })
                }
              >
                {byCard({
                  [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.latitude_gem_visa.label,
                  [PRODUCT_CODE_GO]: OverviewSources.AU.latitude_go_card.label,
                  [PRODUCT_CODE_AFS]: OverviewSources.AU.latitude_afs_card.label,
                  default: '',
                })}
              </Link>{' '}
              is a credit card which gives you access to interest free plans at participating retailers.
            </List.Item>
            <List.Item>
              You have read, understood and agree with the <Link onClick={openPrivacyNoticeModal}>Privacy Notice</Link>,{' '}
              <Link
                newTab
                href={keyFactsSheetLink.url}
                onClick={() =>
                  trackEventSender({
                    location: formTealiumLocation,
                    label: keyFactsSheetLink.label,
                  })
                }
              >
                {keyFactsSheetLink.label}
              </Link>{' '}
              and{' '}
              <Link
                newTab
                href={conditionsOfUseLink.url}
                onClick={() =>
                  trackEventSender({
                    location: formTealiumLocation,
                    label: conditionsOfUseLink.label,
                  })
                }
              >
                {conditionsOfUseLink.label}
              </Link>{' '}
              and <Link onClick={openDeclarationModal}>Declaration and Credit Application</Link>. These documents{' '}
              explain how your personal information is used, the product’s features and terms under which this product{' '}
              operates.
            </List.Item>
            <List.Item>To let us complete a credit check after you've provided your personal details.</List.Item>
            <List.Item>
              To electronically receive your credit contract as set out in the Financial Table and Conditions of Use.
            </List.Item>
            <List.Item>
              To electronically verify your income using a secure process with Verifier (see{' '}
              <Link onClick={openVerifierModal}>Verifier’s terms of service</Link>
              ). If your income can’t be verified this way, we will provide alternatives.
            </List.Item>
          </List>
        </div>
      </Grid.Item>
    </>
  );
};
