import React from 'react';
import { Grid, Container, Text, useBreakpoint, List } from 'basis';
import { ButtonToggle } from 'components';
import { PRODUCT_CODE_AFS, PRODUCT_CODE_AU_GEM, PRODUCT_CODE_GO } from '_config';
import { byCard } from 'utils';
import { LandingContentItem } from '../landing-page-dj/LandingPageDJ.styles';
import ProfileIcon from '../../images/icons/profile-single.svg';
import HomeIcon from '../../images/icons/home.svg';
import WalletIcon from '../../images/icons/finance-wallet.svg';
import MoneyIcon from '../../images/icons/finance-pay-manual.svg';
import IdentityIcon from '../../images/icons/identity.svg';
import StopwatchIcon from '../../images/icons/stopwatch.svg';

export const ProductOverview = () => {
  const breakpoint = useBreakpoint();

  return (
    <>
      <Grid.Item colSpan="all" colSpan-md="0-1" colSpan-lg="0-1" rowSpan="0" rowSpan-md="0" margin-md="0">
        <Container margin="0" padding="0">
          <Text textStyle="heading4" as="h4">
            Who can apply?
          </Text>
        </Container>
      </Grid.Item>
      <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="1" rowSpan-md="0">
        <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
          <LandingContentItem>
            <img src={ProfileIcon} alt="profile-icon" />
            <Text as="p">You must be aged 18 or older</Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={HomeIcon} alt="house-icon" />
            <Text>
              You must be a resident of Australia or a temporary resident who legally has the right to work in Australia
              (and intends to stay) for at least the next 12 months
            </Text>
          </LandingContentItem>
        </div>
      </Grid.Item>

      <Grid.Item colSpan="all" colSpan-md="0-1" colSpan-lg="0-2" rowSpan="2" rowSpan-md="1" margin-md="0">
        <Container margin="0">
          <Text textStyle="heading4" as="h4">
            What you'll need
          </Text>
        </Container>
      </Grid.Item>
      <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="3" rowSpan-md="1">
        <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
          <LandingContentItem>
            <img src={MoneyIcon} alt="money-icon" />
            <Text>Income, debts and expenses Information</Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={IdentityIcon} alt="id-icon" />
            <Text as="p">
              Proof of ID (Australian Driver's Licence, Medicare or Passport) if you consent to using your ID documents{' '}
              to verify your identity
            </Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={WalletIcon} alt="wallet-icon" />
            <Text as="p">Proof of Income (such as payslips, bank statements, tax statements)</Text>
          </LandingContentItem>
        </div>
      </Grid.Item>

      <Grid.Item colSpan="all" colSpan-md="0-1" colSpan-lg="0-2" rowSpan="4" rowSpan-md="2" margin-md="0">
        <Container margin="0">
          <Text textStyle="heading4" as="h4">
            How long will it take?
          </Text>
        </Container>
      </Grid.Item>
      <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="5" rowSpan-md="2">
        <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
          <LandingContentItem>
            <img src={StopwatchIcon} alt="money-icon" />
            <Text>
              You will need 10-15 minutes to complete the application (If we need to review additional information, we
              need up to 2 business days)
            </Text>
          </LandingContentItem>
        </div>
      </Grid.Item>

      <Grid.Item colSpan="all" colSpan-md="0-1" colSpan-lg="0-1" rowSpan="6" rowSpan-md="3" margin-md="0">
        <Container margin="0" margin-md="0 5 0 0" padding="6 0 0 0" padding-md="0 5 0 0">
          <Text textStyle="heading4">Please make sure this product is right for you</Text>
        </Container>
      </Grid.Item>
      <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="7" rowSpan-md="3">
        <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
          <Container margin="0 0 8 0">
            <Text>
              Before we begin your application, we need to ask you a question to confirm the{' '}
              {byCard({
                [PRODUCT_CODE_GO]: 'Latitude GO Mastercard®',
                [PRODUCT_CODE_AU_GEM]: 'Latitude Gem Visa',
                [PRODUCT_CODE_AFS]: 'CreditLine credit card',
                default: '',
              })}{' '}
              is right for you.
            </Text>
          </Container>
          <Container margin="0 0 3 0">
            <strong>Are you after a credit card that gives you access to:</strong>
          </Container>
          <Container margin="0 0 3 0">
            <List>
              <List.Item>interest free payment plans, and</List.Item>
              <List.Item>
                {byCard({
                  [PRODUCT_CODE_AFS]: 'the ability to perform other transactions if required?',
                  default:
                    'the ability to make everyday credit card purchases and / or perform other transactions if required?',
                })}
              </List.Item>
            </List>
          </Container>
          <Container margin="8 0" margin-md="0">
            <ButtonToggle
              name="consentGiven"
              options={[
                {
                  label: 'No',
                  value: 'no',
                },
                {
                  label: 'Yes',
                  value: 'yes',
                },
              ]}
              testId="landing-consent-toggle"
            />
          </Container>
        </div>
      </Grid.Item>
    </>
  );
};
