import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid, Text, useBreakpoint, Message, Link } from 'basis';
import { useTheme as emotionUseTheme } from '@emotion/react';
import { LayoutPage, Form, Errors } from 'components';
import { useStoreValue, APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR } from 'store';
import { submitHandler, getDataLayerElements, updateDataDogContext } from 'utils';
import { useEventTracking } from 'react-event-tracker';
import {
  APPLY_BTN_LABEL,
  OverviewSources,
  PRODUCT_CODE_AFS,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_GO,
  STEP_CONTACT_DETAILS,
} from '_config';
import GemBanner from 'images/banner/gem-banner.png';
import GemBannerMobile from 'images/banner/gem-banner-mobile.png';
import GoBanner from 'images/banner/go-banner.png';
import AfsBanner from 'images/banner/afs-banner.png';
import AfsCardImg from 'images/afs-card.png';
import {
  useInitApplication,
  useErrorTrackingAndUpdateStore,
  useFormInitialValues,
  useDataLayerAndAnalyticsLinks,
} from 'hooks';
import { byCard } from 'utils/byConfig';
import { isFeatureOn } from 'featureToggles';
import { LandingSubHeader } from 'components/merchant/LandingSubHeader';
import { Banner, BannerImage, ContentWrapper } from './LandingPageAu.styles';
import { FeaturesAndBenefitsModal } from './FeaturesAndBenefitsModal';
import { HeaderBannerTextArea } from './HeaderBannerTextArea';
import { useAdblockDetector } from '../../hooks/useAdblockDetector';
import { StartApplicationButton } from './StartApplicationButton';
import { DEV_FEATURES } from '../../__dev';
import { LandingContentItem } from '../landing-page-dj/LandingPageDJ.styles';
import { ProductOverview } from './ProductOverview';
import { ProductTermsAndConditions } from './ProductTermsAndConditions';

const FORM_ID = 'overview';

const formTealiumLocation = 'landing';
const pageTealiumLocation = 'what-to-expect';

export function LandingPageAU() {
  const theme = emotionUseTheme();
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);

  const [storeState, updateStore] = useStoreValue();
  const { trackEvent, getQueryString } = useEventTracking();
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const initialValues = useFormInitialValues(FORM_ID);
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const [isAdblockCheckComplete, isAdblockerDetected] = useAdblockDetector(
    `//tags.tiqcdn.com/utag/latitudefs/apply-funnel/${process.env.REACT_APP_TEALIUM_ENV}/utag.sync.js`,
  );

  useEffect(() => {
    window.DD_RUM?.startSessionReplayRecording();
  }, []);

  const handleSubmitSuccess = data => {
    const updates = {
      applicationRef: data.applicationRef,
      applicationId: data.applicationId,
      isResuming: false,
    };

    updateDataDogContext(updates);

    updateStore({
      activeStep: STEP_CONTACT_DETAILS,
      ...updates,
    });

    trackEventSender(
      {
        category: 'application',
        action: 'application-started',
        location: formTealiumLocation,
        label: APPLY_BTN_LABEL,
      },
      updates,
    );

    history.push(STEP_CONTACT_DETAILS);
  };

  const { submit, loading } = useInitApplication({
    onSuccess: handleSubmitSuccess,
    onError: handleErrorCallback,
  });

  const onSubmit = ({ errors }) => {
    function initialiseApplication() {
      const applicationStartDate = new Date().toISOString();
      const newAcquisition = {
        ...storeState.acquisition,
        overview: {
          consentGiven: true,
        },
      };
      updateStore({ applicationStartDate, acquisition: newAcquisition });
      submit(newAcquisition.overview);
    }
    submitHandler({ submit: initialiseApplication, errors });
  };

  const retrySubmit = () => {
    submit(storeState.acquisition.overview);
  };

  const openFeatureAndBenefitsModal = () => {
    setModalOpen(true);
    trackEvent({
      event: {
        category: 'text-link',
        action: 'interaction',
        location: pageTealiumLocation,
        label: 'View features and benefits',
      },
      ...getDataLayerElements(storeState),
    });
  };

  const closeFeatureAndBenefitsModal = () => {
    setModalOpen(false);
  };

  const getLinkWithAnalytics = key => {
    const gemid2 = getQueryString({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'what-to-expect',
        label: key.label,
      },
    });

    const link = new URL(key.url);
    link.searchParams.set('gemid2', gemid2);
    return link.href;
  };

  if (storeState.applicationErrors) {
    const retry = [APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR].includes(storeState.applicationErrors.type)
      ? retrySubmit
      : null;
    return (
      <LayoutPage hideBackButton>
        <Errors retry={retry} retrying={loading} isPreSubmission />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage noSessionTimeout={!storeState.acquisition.overview.consentGiven} hideBackButton noChildrenPadding>
      <FeaturesAndBenefitsModal
        trackEventSender={trackEventSender}
        getLinkWithAnalytics={getLinkWithAnalytics}
        isOpen={modalOpen}
        onClose={closeFeatureAndBenefitsModal}
      />

      <LandingSubHeader {...storeState.applyAndBuy} />
      <Banner>
        <Container width="100%" padding="0" padding-lg="0">
          <HeaderBannerTextArea openFeatureAndBenefitsModal={openFeatureAndBenefitsModal} />
          <BannerImage theme={theme}>
            <img
              src={
                ['md', 'lg', 'xl'].includes(breakpoint)
                  ? byCard({
                      [PRODUCT_CODE_AU_GEM]: GemBanner,
                      [PRODUCT_CODE_GO]: GoBanner,
                      [PRODUCT_CODE_AFS]: AfsBanner,
                      default: null,
                    })
                  : byCard({
                      [PRODUCT_CODE_AU_GEM]: GemBannerMobile,
                      [PRODUCT_CODE_GO]: GoBanner,
                      [PRODUCT_CODE_AFS]: AfsCardImg,
                      default: null,
                    })
              }
              alt="LFrame card"
            />
          </BannerImage>
        </Container>
        <div className="banner-right" />
      </Banner>
      <Container padding="0 5 5 5" paddingMd="0">
        <Form id={FORM_ID} initialValues={initialValues} onSubmit={onSubmit} hideFormButtons disableFormCache>
          {formData => {
            return (
              <ContentWrapper theme={theme}>
                <Container bg="white" margin="auto">
                  <Grid colsGutter="0" cols="6" rowsGap="4" rowsGap-md="10">
                    <ProductOverview />
                    {formData.state.values.consentGiven === 'yes' && (
                      <>
                        <ProductTermsAndConditions />
                        <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="10" rowSpan-md="5">
                          <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
                            <Container margin="4 0" margin-md="0">
                              <StartApplicationButton
                                backgroundColor={theme.newStageButtonColor}
                                disabled={
                                  !isFeatureOn('launched') ||
                                  (isAdblockCheckComplete &&
                                    isAdblockerDetected &&
                                    !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP)
                                }
                                loading={loading}
                                theme={theme}
                              >
                                {APPLY_BTN_LABEL}
                              </StartApplicationButton>
                            </Container>
                          </div>

                          {isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP && (
                            <Container padding="8 0">
                              <Message severity="stop" bg="secondary.pink.t30">
                                Please turn off your adblocker for the application to work and refresh your browser once
                                it is done.
                              </Message>
                            </Container>
                          )}
                        </Grid.Item>
                      </>
                    )}
                    {formData.state.values.consentGiven === 'no' && (
                      <>
                        <Grid.Item
                          colSpan="all"
                          colSpan-md="0-1"
                          colSpan-lg="0-1"
                          rowSpan="8"
                          rowSpan-md="4"
                          margin-md="0"
                        >
                          <Container margin="0 0 4 0" margin-md="0 5 0 0" padding="6 0 0 0" padding-md="0 5 0 0">
                            <Text textStyle="heading4" as="h4">
                              Looks like this product isn’t right for you
                            </Text>
                          </Container>
                        </Grid.Item>
                        <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="9" rowSpan-md="4">
                          <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
                            <LandingContentItem>
                              <Text>
                                Unfortunately, the product you have selected does not meet your needs. For more
                                information or access to other products, please visit{' '}
                                <Link
                                  href={OverviewSources.AU.lfs_credit_cards.url}
                                  newTab
                                  onClick={() => {
                                    trackEventSender({
                                      category: 'application',
                                      action: 'application-started',
                                      location: formTealiumLocation,
                                      label: OverviewSources.AU.lfs_credit_cards.label,
                                    });
                                  }}
                                >
                                  {OverviewSources.AU.lfs_credit_cards.label}
                                </Link>
                                .
                              </Text>
                            </LandingContentItem>
                          </div>
                        </Grid.Item>
                      </>
                    )}
                    <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5">
                      <Text>
                        {byCard({
                          [PRODUCT_CODE_AFS]: null,
                          default: '*T&Cs, card fee, other charges & exclusions apply.',
                        })}
                      </Text>
                    </Grid.Item>
                  </Grid>
                </Container>
              </ContentWrapper>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
}
