import { gql, ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAuthHeader } from 'utils';

export const UNDISCLOSED_DEBT_FIELDS = gql`
  fragment UndisclosedDebtFields on UndisclosedDebt {
    id
    category
    source
    creditProvider
    currentLimit
    checkPrimaryResidencyAssociated
  }
`;

export const ALL_APPLICATION_FIELDS = gql`
  ${UNDISCLOSED_DEBT_FIELDS}
  fragment AllFields on ApplicationResult {
    applicationRef
    status
    firstName
    middleName
    lastName
    employmentStatus
    productCode
    proofOfIncomeVerified
    statusReason
    approvedCreditLimit
    applicationDate
    acceptedCreditLimit
    accountNumber
    verifiedIncome
    medicareChecked
    passportChecked
    idvNoneChecked
    poiSourceType
    dvsConsent
    finalDVSConsentAttempted
    remainingResendSMSAttempts
    undisclosedDebts {
      ...UndisclosedDebtFields
    }
    residentialStatus
    channel
    merchantId
  }
`;

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_ACQ_APP_APPLY_BFF_URL}/gql`,
  credentials: 'include',
});

const authLink = setContext(async (request, { headers }) => {
  const authHeader = await getAuthHeader();
  return {
    headers: !authHeader
      ? headers
      : {
          ...headers,
          Authorization: authHeader,
        },
  };
});

export const bffGraphqlClient = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
});
