import React from 'react';
import { POITYPE_DOC_UPLOAD, POITYPE_PAYSLIP_UPLOAD, useStoreValue } from 'store';
import { LayoutContent, LayoutPage, YellowMessage } from 'components';
import { Container, Grid, Message, Text } from 'basis';
import { useTheme } from '@emotion/react';
import { config, PRODUCT_CODE_NZ_GEM, PRODUCT_DAVID_JONES } from '_config';
import { getProductName, byCard } from 'utils';

function OutcomeTimeout() {
  const theme = useTheme();
  const [storeState] = useStoreValue();

  const DocUploadSessionTimeout = () => {
    return (
      <Grid rowsGap={6}>
        <Container padding="8 0 0 0">
          <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
            Your session expired
          </Text>
        </Container>
        <Text>
          Application reference number: <b>{storeState.applicationRef}</b>
        </Text>

        <YellowMessage>
          <Message testId="yellow-message" severity="warning-or-significant">
            <Text>
              <strong>
                Unfortunately, your application session has expired, but don’t worry your details have been saved and
                are secure.
              </strong>
            </Text>
            <Text>
              To view the status of your application, please click the link in your email and follow the steps.
            </Text>
          </Message>
        </YellowMessage>

        <Text>Thank you,</Text>
        <Text>The {config.productName} credit card team</Text>
      </Grid>
    );
  };
  const ServiceUnavailableTimeout = () => {
    const productName = getProductName();
    return (
      <Grid rowsGap={6}>
        <Container padding="8 0 0 0">
          <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
            Oops! Something went wrong
          </Text>
        </Container>
        <Text>
          Application reference number: <b>{storeState.applicationRef}</b>
        </Text>

        <YellowMessage>
          <Message testId="yellow-message" severity="warning-or-significant">
            <Text>
              <strong>Looks like the server is taking too long to respond.</strong>
            </Text>
            <Text as="p" margin="1 0 4 0">
              Don’t worry, your details have been saved and are secure.
            </Text>
            <Text as="p">
              To continue with your application, simply click the link in your email. Make sure you have your mobile
              phone nearby for verification.
            </Text>
          </Message>
        </YellowMessage>

        <Text>Thank you for your patience.</Text>
        <Text>
          The {productName}{' '}
          {byCard({
            [PRODUCT_DAVID_JONES]: 'credit card team',
            [PRODUCT_CODE_NZ_GEM]: 'credit card team',
            default: 'Team',
          })}
        </Text>
      </Grid>
    );
  };
  const isDocUploadTimeout =
    storeState.incomeVerificationType === POITYPE_DOC_UPLOAD ||
    storeState.incomeVerificationType === POITYPE_PAYSLIP_UPLOAD;
  return (
    <LayoutPage>
      <Container margin="0 0 0 0" hasBreakpointWidth>
        <LayoutContent rowsGap="0">
          <Container padding="0" margin="0">
            <Grid preset="page" colsGap={6}>
              <Grid.Item colSpan="all">
                {!isDocUploadTimeout && <ServiceUnavailableTimeout />}
                {isDocUploadTimeout && <DocUploadSessionTimeout />}
              </Grid.Item>
            </Grid>
          </Container>
        </LayoutContent>
      </Container>
    </LayoutPage>
  );
}

export { OutcomeTimeout };
